.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.char {
  width: 40px;
  height: 40px;
  /*border: 1px solid black;*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-weight: bold;
  color: #ffffff;
  margin: 5px;
}

.char.valid {
  background-color: #538d4e;
}
.char.invalid {
  background-color: #3a3a3c;
}
.char.present {
  background-color: #b59f3b;
}

.word {
  cursor: pointer;
  padding: 10px;
  margin: 5px;
  font-size: 1.1em;
  text-align: center;
  font-weight: bold;
}
.active {
  background-color: #54d1ff;
}
.inactive {
  background-color: #cccccc;
  color: #a1a1a1;
}
.char-container {
  margin: 0 auto;
  width: 250px;
}
.word-container {
  margin: 0 auto;
  width: 600px;
}

.finder {
  text-align: center;
  margin-top: 50px;
  max-width: 640px;
}

.form-input {
  font-size: 1.25em;
}

.margin-0-5 {
  margin: 0.5em;
}
.margin-0-25 {
  margin: 0.25em;
}
